import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

// Bootstrap & Custom styles
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

// Components
import CustomNavbar from "./Components/CustomNavbar/customNavbar.jsx";
import Footer from "./Components/Footer/footer.jsx";
import MentionsLegales from "./Components/MentionsLegales/MentionsLegales.jsx";
import CGV from "./Components/CGV/cgv.jsx";
import Policy from "./Components/Policy/policy.jsx";
import Remerciement from "./Components/Remerciement/remerciement.jsx";

// Pages
import Accueil from "./Pages/Accueil/accueil.jsx";
import Realisations from "./Pages/Realisations/realisations.jsx";
import Contact from "./Pages/Contact/contact.jsx";
import APropos from "./Pages/APropos/A-Propos.jsx";

function App() {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
    >
      <div className="d-flex flex-column min-vh-100">
        <Router>
          <CustomNavbar />
          <main className="flex-grow-1">
            <Routes>
              <Route path="/" element={<Accueil />} />
              <Route path="/Realisations" element={<Realisations />} />
              <Route path="/APropos" element={<APropos />} />
              <Route path="/Contact" element={<Contact />} />
              <Route path="/MentionsLegales" element={<MentionsLegales />} />
              <Route path="/CGV" element={<CGV />} />
              <Route path="/Policy" element={<Policy />} />
              <Route path="/Remerciement" element={<Remerciement />} />
            </Routes>
          </main>
          <Footer />
        </Router>
      </div>
    </GoogleReCaptchaProvider>
  );
}

export default App;
