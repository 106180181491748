import React from "react";
import "./MentionsLegales.css"; 
const MentionsLegales = () => {
  return (
    <div className="mentions-legales-container">
      <h1>MENTIONS LEGALES</h1>
      <p>
        En vigueur au <span>08/11/2023</span>
      </p>

      <p>
        Conformément aux dispositions des Articles 6-III et 19 de la Loi
        n°2004-575 du 21 juin 2004 pour la Confiance dans l’économie numérique,
        dite L.C.E.N., il est porté à la connaissance des utilisateurs et
        visiteurs, ci-après l"Utilisateur", du site{" "}
        <span>https://www.clermont-toit.fr</span> , ci-après le "Site", les
        présentes mentions légales.
      </p>

      <p>
        La connexion et la navigation sur le Site par l’Utilisateur implique
        acceptation intégrale et sans réserve des présentes mentions légales.
      </p>

      <p>
        Ces dernières sont accessibles sur le Site à la rubrique « Mentions
        légales ».
      </p>

      <h2>ARTICLE 1 - L'ÉDITEUR</h2>
      <p>
        L'édition du Site est assurée par{" "}
        <span>Clermont Toit SARL au capital de 6000 euros</span>, immatriculée
        au Registre du Commerce et des Sociétés de <span>Clermont-Ferrand</span>{" "}
        sous le numéro <span>981351992</span> dont le siège social est situé au{" "}
        <span>12, rue Newton,</span> Numéro de téléphone{" "}
        <span>07 89 43 38 13,</span> Adresse e-mail:{" "}
        <span>contact@clermont-toit.fr.</span> N° de TVA intracommunautaire :{" "}
        <span>FR64981351992</span>. Le Directeur de la publication est{" "}
        <span>Grégory Gauthier</span> ci-après l'Éditeur.
      </p>
      <h2>ARTICLE 2 - L'HÉBERGEUR</h2>
      <p>
        L'hébergeur du Site est la société <span>LWS</span>, dont le siège
        social est situé au <span>10, RUE PENTHIEVRE 75008 PARIS</span>, avec le
        numéro de téléphone : 0177623003
      </p>
      <h2>ARTICLE 3 - ACCÈS AU SITE</h2>

      <p>
        Le Site est accessible en tout endroit, 7j/7, 24h/24 sauf cas de force
        majeure, interruption programmée ou non et pouvant découler d’une
        nécessité de maintenance.
      </p>
      <p>
        En cas de modification, interruption ou suspension du Site, l'Éditeur ne
        saurait être tenu responsable.
      </p>
      <h2>ARTICLE 4 - COLLECTE DES DONNÉES</h2>

      <p>
        Le Site assure à l'Utilisateur une collecte et un traitement
        d'informations personnelles dans le respect de la vie privée
        conformément à la loi n°78-17 du 6 janvier 1978 relative à
        l'informatique, aux fichiers et aux libertés.
      </p>
      <p>
        En vertu de la loi Informatique et Libertés, en date du 6 janvier 1978,
        l'Utilisateur dispose d'un droit d'accès, de rectification, de
        suppression et d'opposition de ses données personnelles. L'Utilisateur
        exerce ce droit : <br />
        👉🏽par mail à l'adresse email : <span>contact@clermont-toit.fr</span>
        <br /> 👉🏽 par voie postale au{" "}
        <span>12, rue Newton, 63100 Clermont-Ferrand ;</span><br />
        👉🏽 via un formulaire de contact sur le site internet :{" "}
        <span>https://www.clermont-toit.fr</span>;
      </p>

      <p>
        Toute utilisation, reproduction, diffusion, commercialisation,
        modification de toute ou partie du Site, sans autorisation de l’Éditeur
        est prohibée et pourra entraînée des actions et poursuites judiciaires
        telles que notamment prévues par le Code de la propriété intellectuelle
        et le Code civil.
      </p>
      <p>
        Pour plus d’informations, se reporter aux CGV du site
        https://www.clermont-toit.fr accessible à la rubrique "CGV"
      </p>

      <p>Rédigé sur http://legalplace.fr</p>
    </div>
  );
};

export default MentionsLegales;
