import React from "react";
import "./mainpage.css";

const MainPage = () => {
  return (
    <main className="main">
      <div className="container-fluid p-0 container-banner">
        <video
          autoPlay
          playsInline
          muted
          loop
          className="w-100"
          src="https://www.clermont-toit.fr/Assets/Videos/flocage&toit.mp4"
          type="video/mp4"
        ></video>
        <div className="container text-center">
          <div className="row">
            <div className="col-12">
              <div className="content-text">
                <div className="separateur my-2"></div>{" "}
                <div className="description">
                  <h2>Votre satisfaction est notre motivation</h2>
                  <p className="description-center">
                    Vous avez dit:
                    <br />
                    Couverture ?<br />
                    Qualité ?<br />
                    Savoir-faire artisanal ?
                  </p>
                </div>
                <div className="separateur my-2"></div>
                <div className="contact-button">
                  <a
                    href="./contact"
                    className="btn"
                    title="Contactez notre équipe"
                  >
                    Contactez notre équipe
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="py-3">
        <div className="container">
          <h2>
            Pourquoi choisir <strong>Clermont Toit</strong> pour nous confier
            votre projet de rénovation ?
          </h2>
          <div className="description py-2">
            <p>
              La raison principale de notre création d’entreprise est de pouvoir
              proposer sur le marché une prestation de qualité et une vision
              jeune et dynamique, tout en plaçant votre satisfaction au cœur de
              notre démarche.
            </p>
            {/* ... Reste du contenu ... */}
          </div>
          <div className="separateur my-2"></div>
          <p className="description-center">
            Alors, n’attendez plus !<br />
            Un projet personnalisé ?<br />
            Un conseil sur-mesure ?<br />
            Une écoute attentive de vos besoins ?<br />
            Voilà autant de raisons de choisir <strong>Clermont Toit</strong>,
            dès aujourd’hui !
          </p>
          <div className="separateur my-2"></div>
        </div>
      </section>
    </main>
  );
};

export default MainPage;
