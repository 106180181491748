import React from "react";
import "./accueil.css";

// Components
import Temoignages from "../../Components/Temoignages/temoignages.jsx";
import MainPage from "../../Components/MainPage/mainpage.jsx";
import Carousel3D from "../../Components/Carousel3D/carousel3D.jsx";

const Accueil = () => {
  return (
    <div className="container-fluid p-0">
      <MainPage />
      <Carousel3D />
      <Temoignages />
    </div>
  );
};

export default Accueil;
