// Remerciement.jsx
import React from "react";
import { Link } from "react-router-dom"; 
import "./remerciement.css";

function Remerciement() {
  return (
    <div className="thank-you-message">
      <div className="wrapper-1">
        <div className="wrapper-2">
          <h1 className="merci">Merci pour votre message !</h1>
          <p>Nous reviendrons vers vous dès que possible.</p>
          <br />
          <p className="signature">Clermont Toit</p>
          <Link to="/" className="go-home">
            Accueil
          </Link>{" "}
          
        </div>
      </div>
    </div>
  );
}

export default React.memo(Remerciement);
