import React from "react";
import "./realisations.css";
import Galerie from "../../Components/Galerie/galerie.jsx";

const Realisations = () => {
  return (
    <div>
      <h1 className="realisations"> </h1>
      <Galerie />
    </div>
  );
};

export default Realisations;
