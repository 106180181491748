import React, { useState } from "react";
import "./temoignages.css";


const Temoignages = () => {
  const [nom, setNom] = useState("");
  // eslint-disable-next-line
  const [prenom, setPrenom] = useState("");
  const [commentaire, setCommentaire] = useState("");
  // eslint-disable-next-line
  const [message, setMessage] = useState("");
  // eslint-disable-next-line
  const handleSubmit = (e) => {
    e.preventDefault();

    // Validation simple côté client
    if (!nom.trim() || !commentaire.trim()) {
      setMessage("Veuillez remplir tous les champs correctement.");
      return;
    }

    // Réinitialiser le message
    setMessage("");

    // Insertion du témoignage dans la base de données
    fetch("/api/temoignages", {
      method: "POST",
      body: JSON.stringify({ nom, commentaire }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Problème lors de l'envoi du témoignage");
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        setMessage("Votre témoignage a été envoyé avec succès.");
        // Réinitialiser le formulaire
        setNom("");
        setCommentaire("");
      })
      .catch((error) => {
        console.error(error);
        setMessage("Erreur lors de l'envoi du témoignage.");
      });
  };

  return (
    <div>
      <section id="temoignages">
        <h2>Témoignages de nos clients</h2>

        <div
          class="elfsight-app-c81f12ef-630d-4727-91f1-f852aa80c5d6"
          data-elfsight-app-lazy
        ></div>
      </section>
    </div>
  );
};

export default Temoignages;
