const Photos = [
  {
    id: 1,
    image:
      "https://www.clermont-toit.fr/Assets/Images/Photos-Chantier/Couverture/Couverture.webp",
    title: "Devis rénovation",
    description:
      "Lors de la préparation d'un devis pour la rénovation complète d'une maison abandonnée.",
    category: "Couverture",
  },
  {
    id: 2,
    image:
      "https://www.clermont-toit.fr/Assets/Images/Photos-Chantier/Couverture/Couverture1.webp",
    title: "Rénovation toiture",
    description:
      "Un client nous a sollicités pour la rénovation de sa toiture, envisageant initialement la pose d'un pare-pluie. Après une évaluation, nous avons recommandé contre cette option en raison de l'état de la toiture et de la minime différence de coût.",
    category: "Couverture",
  },
  {
    id: 3,
    image:
      "https://www.clermont-toit.fr/Assets/Images/Photos-Chantier/Couverture/Couverture2.webp",
    title: "Rénovation toiture",
    description:
      "Le client a finalement opté pour une réfection complète de sa couverture sur nos conseils. De plus, nous avons procédé à la conception et à l'installation de deux nouveaux Velux équipés de volets roulants.",
    category: "Couverture",
  },
  {
    id: 4,
    image:
      "https://www.clermont-toit.fr/Assets/Images/Photos-Chantier/Velux/velux-avant.webp",
    title: "Velux d'origine",
    description:
      "Suite à une fuite significative au niveau de son Velux, une cliente a fait appel à nos services. Nous avons effectué le remplacement intégral des liteaux endommagés. Par la suite, un nouveau Velux a été installé, accompagné d'un nouvel habillage en zinc. Intervention réalisée à Orcines. ",
    category: "Velux",
  },
  {
    id: 5,
    image:
      "https://www.clermont-toit.fr/Assets/Images/Photos-Chantier/Velux/velux-pendant.webp",
    title: "Remplacement intégral",
    description:
      "Suite à une fuite significative au niveau de son Velux, une cliente a fait appel à nos services. Nous avons effectué le remplacement intégral des liteaux endommagés. Par la suite, un nouveau Velux a été installé, accompagné d'un nouvel habillage en zinc. Intervention réalisée à Orcines.",
    category: "Velux",
  },
  {
    id: 6,
    image:
      "https://www.clermont-toit.fr/Assets/Images/Photos-Chantier/Velux/velux-apres.webp",
    title: "Nouveau vélux",
    description:
      "Suite à une fuite significative au niveau de son Velux, une cliente a fait appel à nos services. Nous avons effectué le remplacement intégral des liteaux endommagés. Par la suite, un nouveau Velux a été installé, accompagné d'un nouvel habillage en zinc. Intervention réalisée à Orcines.",
    category: "Velux",
  },
  {
    id: 7,
    image:
      "https://www.clermont-toit.fr/Assets/Images/Photos-Chantier/Zinguerie/zinguerie-avant.webp",
    title: "Zinguerie d'origine",
    description:
      "Après que des vents violents aient endommagé la zinguerie de sa maison, notre cliente nous a sollicités pour une évaluation des dommages. Nous avons observé que certains éléments de la zinguerie avaient été arrachés.",
    category: "Zinguerie",
  },
  {
    id: 8,
    image:
      "https://www.clermont-toit.fr/Assets/Images/Photos-Chantier/Zinguerie/zinguerie-pendant.webp",
    title: "Zinguerie en cours",
    description:
      "En conséquence, nous avons proposé un devis pour le remplacement complet de la zinguerie sur un côté du toit, incluant bandeaux et mains courantes en zinc, afin de restaurer l'étanchéité de la rive du toit.",
    category: "Zinguerie",
  },
  {
    id: 9,
    image:
      "https://www.clermont-toit.fr/Assets/Images/Photos-Chantier/Zinguerie/zinguerie-pendant1.webp",
    title: "Zinguerie en cours",
    description:
      "Nous avons également noté que plusieurs cheminées désaffectées étaient dépourvues de chapeaux, ou que ces derniers étaient défectueux, risquant de causer d'autres désagréments.",
    category: "Zinguerie",
  },
  {
    id: 10,
    image:
      "https://www.clermont-toit.fr/Assets/Images/Photos-Chantier/Zinguerie/zinguerie-apres.webp",
    title: "Nouvelle zinguerie",
    description:
      "Sur demande de notre cliente, nous avons installé des chapeaux en zinc sur ces cheminées pour prévenir tout problème futur. L'intervention a eu lieu à Saint-Amant-Tallende.",
    category: "Zinguerie",
  },
  {
    id: 11,
    image:
      "https://www.clermont-toit.fr/Assets/Images/Photos-Chantier/Zinguerie/zinguerie-lejeune.webp",
    title: "Zinguerie en cours",
    description:
      "Un client a fait appel à nos services pour la réalisation complète des travaux de zinguerie sur sa propriété.",
    category: "Zinguerie",
  },
  {
    id: 12,
    image:
      "https://www.clermont-toit.fr/Assets/Images/Photos-Chantier/Zinguerie/zinguerie-lejeune1.webp",
    title: "Zinguerie finalisée",
    description:
      "Nous avons pris en charge l'installation de l'ensemble des éléments de zinguerie, y compris les couloirs et le chéneau encastré, en utilisant du zinc de qualité, comme le montrent les photos. Le projet a été mené à bien à Durtol.",
    category: "Zinguerie",
  },
];

export default Photos;
