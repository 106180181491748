import React from 'react';
import './cgv.css'; 

const CGV = () => {
  return (
    <div className="cgv-container">
      <h1>Conditions générales d’intervention et de vente</h1>
      <section>
        <h2>1- CONTENU ET DOMAINE D’APPLICATION</h2>
        <p>
          1.1 Les présentes conditions générales s’appliquent à toutes les
          commandes du maître d’ouvrage. En conséquence, la passation d’une
          commande par le maître d’ouvrage emporte son adhésion sans réserve aux
          présentes conditions générales.
        </p>
        <p>
          1.2 Les présentes conditions générales prévalent sur toutes autres
          conditions générales qui pourraient leur être opposées.
        </p>
        <p>
          1.3 L’entreprise se réserve le droit de déroger à certaines clauses
          des présentes dans les conditions particulières (ex : devis).
        </p>
        <p>1.4 L’entreprise peut sous-traiter tout ou partie de son marché.</p>
      </section>
      <section>
        <h2>2 - CONCLUSION DU MARCHÉ</h2>
        <p>
          2.1 Sauf mention contraire dans les conditions particulières, la durée
          de validité de l’offre de l’entreprise est d’un mois à compter de sa
          date d’établissement. Au-delà de cette période, l’entreprise n’est
          plus tenue par les termes de son offre. Les devis réalisés par
          l’entreprise sont gratuits, sauf indication contraire sur ces
          derniers.
        </p>
        <p>
          2.2 La commande est définitive lors du retour d’un exemplaire de
          l’offre non modifiée signée par le maître de l’ouvrage et accompagnée
          de l’acompte tel que prévu à l’article 8.1 des présentes conditions
          générales.
        </p>
        <p>
          2.3 Le maître de l’ouvrage indique, avant conclusion du marché, à
          l’entrepreneur par lettre recommandée avec accusé de réception s’il
          entend demander un prêt pour payer en totalité ou en partie les
          travaux, faute de quoi, il est réputé ne pas emprunter et perdre le
          bénéfice des dispositions du code de la consommation sur le crédit
          immobilier et le crédit à la consommation.
        </p>
      </section>
      <section>
        <h2>3 - CONDITIONS D’EXÉCUTION DES TRAVAUX</h2>
        <p>
          3.1 L’entreprise est assurée pour la couverture de risques mettant en
          jeu sa responsabilité. Les prestations sont réalisées dans le respect
          des règles de l’art et conformément aux DTU applicables.
        </p>
        <p>
          3.2 Le délai de réalisation des travaux est prévu aux conditions
          particulières. Le délai d’exécution commencera à courir à compter de
          la réception par l’entreprise de l’acompte à la commande/de
          l’obtention des autorisations d’urbanisme/ de l’acceptation du crédit.
          Le délai d’exécution sera prolongé de plein droit dans les cas
          suivants : intempéries telles que définies par le code du travail et
          rendant impossible toute exécution des travaux convenus, cas de force
          majeure, travaux supplémentaires ou imprévus, retard du fait du maître
          d’ouvrage ou de non-exécution de ses obligations par le maître de
          l’ouvrage ou un tiers mandaté par ce dernier.
        </p>
        <p>
          3.3 L’eau, l’électricité, les accès, les aires de stockage et
          d’installation nécessaires à la réalisation des travaux seront mis à
          la disposition de l’entreprise en quantités suffisantes, gratuitement
          et à proximité des travaux.
        </p>
      </section>
      <section>
        .<h2>4 - RÉMUNÉRATION DE L’ENTREPRENEUR</h2>
        <p>
          4.1 La facturation définitive correspondra au montant du décompte
          définitif établi par l’entreprise prenant en compte les travaux
          réellement exécutés, y compris les éventuels travaux supplémentaires.
        </p>
      </section>

      <section>
        <h2>5 - TRAVAUX SUPPLÉMENTAIRES, URGENTS OU IMPRÉVISIBLES</h2>
        <p>
          5.1 Tous travaux non prévus explicitement dans l’offre seront
          considérés comme travaux supplémentaires. Ils donneront lieu, avant
          leur exécution, à la signature d’un avenant mentionnant notamment le
          prix de ces nouveaux travaux et le nouveau délai d’exécution, le cas
          échéant.
        </p>
        <p>
          5.2 L’entrepreneur est habilité à prendre en cas d’urgence, toutes
          dispositions conservatoires nécessaires, sous réserve d’en informer le
          maître de l’ouvrage.
        </p>
      </section>

      <section>
        <h2>6 - HYGIÈNE ET SÉCURITÉ</h2>
        <p>
          6.1 Des locaux décents à usage de vestiaires, réfectoire et WC devront
          être mis à la disposition du personnel de l’entreprise par les soins
          du maître de l’ouvrage en quantités suffisantes, gratuitement et à
          proximité des travaux. Le chantier devra être équipé d’un branchement
          d’eau potable et d’une arrivée de courant. En cas d’impossibilité ou
          d’insuffisance, les installations nécessaires seront facturées au
          maître de l’ouvrage. L’entrepreneur ne peut être tenu d’effectuer des
          travaux dont l’exécution présenterait un caractère dangereux, sans que
          soient mis en place les systèmes de prévention réglementaires.
        </p>
      </section>

      <section>
        <h2>7 - RÉCEPTION DES TRAVAUX</h2>
        <p>
          7.1 La réception des travaux a lieu dès leur achèvement. Elle est
          prononcée à la demande de l’entrepreneur, par le maître de l’ouvrage,
          avec ou sans réserve.
        </p>
        <p>
          7.2 La réception libère l’entrepreneur de toutes les obligations
          contractuelles autres que les garanties légales.
        </p>
        <p>
          7.3 Les motifs de refus de réception doivent être précisés par lettre
          recommandée avec accusé de réception dans les trois jours suivant la
          demande de l’entreprise. Si la visite a eu lieu, les motifs doivent
          être indiqués sur le procès-verbal de refus.
        </p>
        <p>
          7.4 Si la réception doit intervenir judiciairement, les frais
          correspondants seront à la charge du maître de l’ouvrage.
        </p>
      </section>

      <section>
        <h2>8 - PAIEMENTS</h2>
        <p>
          8.1 Sauf mention contraire dans les conditions particulières, il est
          demandé un acompte de 30 % du montant du marché à la commande et avant
          tout début d’exécution des travaux. L’entreprise pourra demander le
          paiement d’acomptes mensuels (situations de travaux) au prorata de
          l’avancement pour tous travaux d’une durée supérieure à 30 jours. En
          fin de travaux, l’entreprise facturera le solde des travaux dans les
          conditions prévues à l’article 4.
        </p>
        <p>
          8.2 Aucune retenue de garantie ne s’applique aux marchés de
          l’entreprise.
        </p>
        <p>
          8.3 Les demandes de paiements et factures à compter de leur émission
          seront réglées à l’entreprise par chèque sous 15 jours. En cas de non-
          paiement à la date portée sur la facture, des pénalités de retard
          égales au taux d’l’intérêt appliqué par la Banque Centrale européenne
          à son opération de refinancement la plus récente majoré de 10 points
          de pourcentage seront dues à l’entreprise.
        </p>
        <p>
          8.4 Pour les seuls clients professionnels ressortissant aux
          dispositions de l’article L. 441-10 du code de commerce, tout retard
          de paiement ouvre droit à l'égard du créancier à une indemnité
          forfaitaire pour frais de recouvrement de 40 euros. Lorsque les frais
          de recouvrement exposés sont supérieurs à ce montant, l’entreprise
          peut demander une indemnisation complémentaire, sur justification.
        </p>
        <p>
          8.5 En cas de non-paiement à échéance, l’entrepreneur pourra suspendre
          les travaux dans un délai de 15 jours, après mise en demeure préalable
          au maître de l’ouvrage restée infructueuse.
        </p>
        <p>
          8.6 En cas de résiliation unilatérale du fait du maître d’ouvrage
          avant le démarrage des travaux, et sauf cas de force majeure, le
          montant des acomptes versés sera conservé par l’entreprise. Cette
          dernière sera en droit d’obtenir dédommagement de toutes ses dépenses,
          de tous ses travaux, et de tout ce qu’elle aurait pu gagner en
          exécutant le marché.
        </p>
      </section>

      <section>
        <h2>9 - GARANTIES DE L’ENTREPRISE</h2>
        <p>
          Lorsque le montant des travaux, déduction faite de l’acompte versé à
          la commande, est supérieur à 12.000 euros, le maître de l’ouvrage doit
          en garantir le paiement de la façon suivante : 1) Lorsqu’il recourt à
          un crédit destiné exclusivement et en totalité au paiement des travaux
          objet du marché, le maître de l’ouvrage fera le nécessaire pour que
          les versements, effectués par l’établissement prêteur, parviennent à
          l’entrepreneur aux échéances convenues dans le marché (2ème alinéa de
          l’article 1799-1 du Code civil). Le maître de l’ouvrage adresse à
          l’entrepreneur copie du contrat attestant de la délivrance du prêt 2)
          Lorsqu’il ne recourt pas à un crédit spécifique travaux, le maître de
          l’ouvrage (à l’exception des consommateurs) fournit, au plus tard à
          l’expiration d’un délai de15 jours suivant la conclusion du marché, le
          cautionnement visé au 3ème alinéa de l’article 1799-1 du Code civil).
          Tant que le cautionnement ou l’attestation du crédit n’est pas fourni,
          l’entrepreneur ne commencera pas les travaux. Le délai d'exécution est
          prolongé en conséquence, si la date prévue pour le début des travaux
          est antérieure à celle de la fourniture du cautionnement ou de
          l’attestation du prêt.
        </p>
      </section>

      <section>
        <h2>10- GARANTIE LEGALE DE CONFORMITE</h2>
        <p>
          Le consommateur dispose d'un délai de deux ans à compter de la
          délivrance du bien pour obtenir la mise en œuvre de la garantie légale
          de conformité en cas d'apparition d'un défaut de conformité. Durant ce
          délai, le consommateur n'est tenu d'établir que l'existence du défaut
          de conformité et non la date d'apparition de celui-ci. Lorsqu’il agit
          en garantie légale de conformité, le consommateur : - Bénéficie d’un
          délai de 2 ans pour agir - Peut choisir entre le remplacement du bien,
          sous réserve des conditions de coût prévues à l’article L217-9 du code
          de la consommation : l’entreprise ne peut pas procéder selon le choix
          de l’acheteur si ce choix entraîne un coût manifestement
          disproportionné au regard de l’autre modalité, compte tenu de la
          valeur du bien ou de l’importance du défaut ; - Est dispensé de
          rapporter la preuve de l’existence du défaut au moment de l’achat si
          celui-ci apparaît dans le délai fixé par l’article L.217-7 du code de
          la consommation ; - Peut décider de mettre en œuvre la garantie contre
          les défauts de la chose vendue au sens de l’article 1641 du code civil
          ; - Peut, dans cette hypothèse, choisir entre l’action rédhibitoire et
          l’action estimatoire prévues par l’article 1644 du code civil. Rappel
          : Article 1641 du code civil : Le vendeur est tenu de la garantie à
          raison des défauts cachés de la chose vendue qui la rendent impropre à
          l’usage auquel on la destine ou qui diminuent tellement cet usage que
          l’acheteur ne l’aurait pas acquise, ou n’en aurait donné qu’un moindre
          prix, s’il les avait connus.
        </p>
      </section>

      <section>
        <h2>11 - PROPRIÉTÉ INTELLECTUELLE</h2>
        <p>
          Les études, devis, plans et documents de toute nature remis ou envoyés
          par l’entreprise restent toujours son entière propriété ; ils doivent
          être rendus sur sa demande. Ils ne peuvent être communiqués, ni
          reproduits, ni exécutés par un tiers, sans autorisation écrite de
          l’entreprise.
        </p>
      </section>

      <section>
        <h2>12- FORCE MAJEURE</h2>
        <p>
          Les parties ne pourront être tenues pour responsables si la
          non-exécution ou le retard dans l’exécution de l’une quelconque de
          leurs obligations, telles que décrites dans les présentes découle d’un
          cas de force majeure, au sens de l’article 1218 du code civil. La
          partie constatant l’événement devra sans délai informer l’autre partie
          de son impossibilité à exécuter sa prestation et s’en justifier auprès
          de celle-ci. La suspension des obligations ne pourra en aucun cas être
          une cause de responsabilité pour non-exécution de l’obligation en
          cause, ni induire le versement de dommages et intérêts ou pénalités de
          retard. Pendant cette suspension, les parties conviennent que les
          frais engendrés par la situation seront à la charge du client.
        </p>
      </section>

      <section>
        <h2>13 – PROTECTION DES DONNEES PERSONNELLES</h2>
        <p>
          Les données personnelles collectées par l’entreprise sont enregistrées
          dans son fichier clients. L’ensemble des informations collectées sont
          nécessaires à la conclusion et à l’exécution du contrat et seront
          principalement utilisées pour la bonne gestion des relations avec le
          maître de l’ouvrage, le traitement des commandes et la promotion des
          services de l’entreprise. Les informations personnelles collectées
          seront conservées aussi longtemps que nécessaire à l’exécution du
          contrat, à l’accomplissement par l’entreprise de ses obligations
          légales et réglementaires ou encore à l’exercice des prérogatives lui
          étant reconnues par la loi et la jurisprudence. L’accès aux données
          personnelles est strictement limité aux employés et préposés de
          l’entreprise, habilités à les traiter en raison de leurs fonctions.
          Les informations recueillies pourront éventuellement être communiquées
          à des tiers liés à l’entreprise par contrat pour l’exécution de tâches
          sous-traitées nécessaires à la gestion des commandes, sans qu’aucune
          autorisation du maître d’ouvrage soit nécessaire. En dehors des cas
          énoncés ci-dessus, l’entreprise s’engage à ne pas vendre, louer, céder
          ou donner accès à des tiers aux données sans consentement préalable du
          maître d’ouvrage, à moins d’y être contrainte en raison d’un motif
          légitime (obligation légale, lutte contre la fraude ou l’abus,
          exercice des droits de la défense, etc.). Enfin, en cas de transfert
          des données dehors de l’Union européenne (« U.E »), il est rappelé que
          les destinataires externes à l’entreprise seraient contractuellement
          tenus de mettre en œuvre les efforts et les moyens nécessaires afin de
          garantir un niveau de protection équivalent à celui fourni au sein de
          l’U.E. Conformément aux dispositions légales et réglementaires
          applicables, le maître d’ouvrage bénéficie d’un droit accès, de
          rectification, de portabilité et d’effacement de ses données ou encore
          de limitation du traitement. Il peut également pour des motifs
          légitimes, s’opposer au traitement des données le concernant. Le
          maître de l’ouvrage peut, sous réserve de la production d’un
          justificatif d’identité valide, exercer ses droits en contactant
          Clermont Toit.
        </p>
      </section>

      <section>
        <h2>14 - CONTESTATIONS</h2>
        <p>
          14.1 Lorsqu’une des parties ne se conforme pas aux conditions du
          marché, l’autre partie la met en demeure d’y satisfaire par lettre
          recommandée avec accusé de réception.
        </p>
        <p>
          14.2 Le maître de l’ouvrage, consommateur personne physique, peut,
          après échec de la procédure prévue à l’alinéa ci-dessus, recourir à la
          médiation de la consommation en s’adressant à : N.B. L’entreprise est
          légalement tenue de désigner un médiateur de la consommation mais le
          choix dudit médiateur reste totalement libre et relève de sa seule
          volonté. Pour information, afin de faire bénéficier les adhérents de
          conditions tarifaires préférentielles, la FFB a noué des partenariats
          avec certains médiateurs spécialisés dans le domaine du BTP, auxquels
          l’entreprise est libre de recourir ou non : CM2C, 14 rue Saint Jean
          75017 PARIS E-mail : cm2c@cm2c.net Site internet :
          cm2c.net/declarer-un-litige.php Quel que soit son choix, l’entreprise
          doit s’enregistrer sur la plateforme du médiateur choisi afin que ses
          éventuels litiges soient pris en charge.
        </p>
      </section>

      <section>
        <p>
          15.3 En cas de litige avec un maître de l’ouvrage consommateur, les
          litiges seront portés devant le tribunal du lieu d’exécution des
          travaux ou du domicile du maître de l’ouvrage. En cas de litige avec
          un maître de l’ouvrage professionnel, les litiges seront portés devant
          les tribunaux du ressort de la Cour d’Appel de Riom.
        </p>
      </section>
    </div>
  );
};

export default CGV;