import React from "react";
import "./policy.css";

const PrivacyPolicy = () => (
  <div className="privacy-policy-container">
    <h1>Politique de Confidentialité</h1>

    <p>
      Cette politique de confidentialité a pour objectif de vous expliquer
      pourquoi nous recueillons vos données et comment nous nous engageons à les
      protéger. Clermont Toit s'engage en faveur de la protection de vos données
      personnelles et de votre vie privée.
    </p>

    <p>
      À ce titre, et en application du Règlement Général de Protection des
      Données (ci-après dénommé "RGPD"), nous vous communiquons ci-après les
      conditions dans lesquelles vos données personnelles sont appelées à être
      traitées par nos soins.
    </p>

    <h2>Quelles données personnelles traitons-nous ?</h2>
    <p>
      <u>Finalités :</u>
    </p>
    <ul>
      <li>Traiter et répondre à vos messages,</li>
      <li>Créer et gérer l’accès à vos comptes,</li>
      <li>Gérer vos inscriptions à nos newsletters,</li>
      <li>Améliorer notre suivi et service client,</li>
      <li>Mémoriser vos choix quant à l’utilisation des cookies,</li>
      <li>Traiter et répondre à vos demandes d’exercice de droits,</li>
      <li>
        Pour répondre aux exigences réglementaires en vigueur ou en cours
        d’adoption.
      </li>
    </ul>

    <h2>Catégories des données :</h2>
    <p>
      Des coordonnées (par exemple nom, prénom, numéro de téléphone, email) ;
      Des informations techniques et de localisations générées dans le cadre de
      l’utilisation de nos services.
    </p>

    <h2>Fondement juridiques des traitements</h2>
    <p>
      Les traitements de données à caractère personnel mis en œuvre sont fondés
      :
    </p>
    <ul>
      <li>
        Soit sur le consentement de la personne concernée (Article 6.1.a du
        RGPD) pour tous les traitements qui nécessitent le recueil préalable du
        consentement.
      </li>
      <li>
        Soit pour l’exécution d’un contrat ou l’exécution de mesures
        précontractuelles,
      </li>
      <li>
        Soit pour la poursuite d’un intérêt légitime (Article 6.1.e du RGPD).
      </li>
      <li>Soit pour le respect d’une obligation légale ou règlementaire.</li>
    </ul>

    <p>
      Vos données sont conservées pour la durée nécessaire à l’accomplissement
      des finalités mentionnées ci-dessus.
    </p>

    <h2>Quels sont les destinataires de vos données ?</h2>
    <p>
      Vos données personnelles sont destinées à Clermont Toit et peuvent être
      traitées par :
    </p>
    <ul>
      <li>Nos services internes.</li>
      <li>Des entreprises ou personnes de confiance.</li>
      <li>Des sous-traitants techniques.</li>
    </ul>

    <h2>Comment Clermont Toit préserve la sécurité de vos données ?</h2>
    <p>
      Nous mettons en place toutes les mesures organisationnelles et techniques
      permettant d’assurer un niveau approprié de sécurité à vos données
      personnelles.
    </p>

    <h2>Quels sont vos droits sur vos données personnelles ?</h2>
    <p>
      Conformément à la Réglementation Applicable, vous disposez de droits
      spécifiques relatifs à vos données personnelles.
    </p>

    <h2>Qui contacter pour toutes les demandes liées au RGPD ?</h2>
    <p>
      Pour exercer vos droits, vous pouvez nous contacter à l'adresse
      contact@clermont-toit.fr.
    </p>

    <h2>Réserve de modification de la Politique de protection des données</h2>
    <p>
      La présente Politique de protection des données personnelles peut être
      amenée à évoluer.
    </p>

    <h1>Politique de Cookies</h1>
    <h2>Qu'est-ce qu'un "cookie" ?</h2>

    <p>
      Les cookies sont de petits fichiers texte qu'un site web enregistre sur
      votre ordinateur ou votre appareil mobile lorsque vous visitez le site.
      Ils facilitent votre expérience en ligne en sauvegardant les informations
      de navigation. <br />
      <br />
      Grâce aux cookies, les sites peuvent vous garder connecté, se souvenir de
      vos préférences de site et vous proposer un contenu personnalisé. Les
      cookies peuvent également être utilisés pour établir des statistiques sur
      l'expérience de navigation et pour montrer des publicités ciblées. <br />
      <br />
      En général, les cookies peuvent être classés par : <br />
      <br />
      <u>Domaine : </u>
      <br />
      <br />
      - Les cookies de première partie sont émis par un site web qu'un
      utilisateur consulte directement. <br />
      <br />- Les cookies tiers ne sont pas créés par le site web consulté, mais
      par un tiers comme Google Analytics, DoubleClick, Facebook, Twitter,
      LinkedIn, Youtube, Vimеo, etc. <br />
      <br />
      <u>Objectif : </u><br />
      <br />
      - Les cookies strictement nécessaires sont requis pour que le site web
      fonctionne correctement. <br />
      - Les cookies de préférences permettent à un site web de se souvenir des
      choix que vous avez faits dans le passé. <br />- Les cookies de
      statistiques aident le propriétaire du site web à collecter des données
      statistiques et à comprendre comment les visiteurs interagissent avec le
      site web. <br />
      - Les cookies marketing suivent l'activité en ligne de l'utilisateur pour
      aider les annonceurs à diffuser des publicités plus pertinentes. <br />
      <br />
      <u>Durée : </u>
      <br />
      <br />
      - Les cookies de session qui sont effacés lorsque l'utilisateur ferme le
      navigateur. <br />- Les cookies persistants qui restent sur le dispositif
      de l'utilisateur pendant une certaine période de temps. Vos préférences
      concernant les cookies quand vous arrivez pour la première fois sur le
      site, un bandeau cookie vous propose d’accepter ou de refuser les Cookies
      qui ne sont pas essentiels au fonctionnement du site. Vous pouvez refuser/
      désactiver les Cookies à tout moment, à l’exception des Cookies
      nécessaires au fonctionnement stable du site. Vous avez la possibilité de
      modifier à tout moment vos préférences relatives à la gestion des cookies.
    </p>
  </div>
);

export default PrivacyPolicy;
