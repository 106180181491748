import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import "./formulaire.css";

function FormulaireComponent() {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [prenom, setPrenom] = useState("");
  const [nom, setNom] = useState("");
  const [email, setEmail] = useState("");
  const [telephone, setTelephone] = useState("");
  const [message, setMessage] = useState("");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }

    const token = await executeRecaptcha("homepage");
    
    // console.log(token); 

    // Inclusion du token dans formData pour l'envoyer au serveur
    const formData = new FormData();
    formData.append("prenom", prenom);
    formData.append("nom", nom);
    formData.append("email", email);
    formData.append("telephone", telephone);
    formData.append("message", message);
    formData.append("g-recaptcha-response-contact", token); 

    try {
      const response = await fetch(
        "https://www.clermont-toit.fr/formContact.php",
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        const responseText = await response.text();
        alert(responseText); // Affiche le message de statut renvoyé par PHP
        setIsFormSubmitted(true);
        resetForm(); // Réinitiale le formulaire ici si nécessaire
      } else {
        alert("Une erreur est survenue lors de l'envoi du formulaire.");
      }
    } catch (error) {
      console.error("Erreur lors de l'envoi du formulaire:", error);
      alert(
        "Erreur lors de l'envoi du formulaire. Veuillez vérifier votre connexion et réessayer."
      );
    }
  };

  // Fonction pour réinitialiser le formulaire
  const resetForm = () => {
    setPrenom("");
    setNom("");
    setEmail("");
    setTelephone("");
    setMessage("");
  };

if (isFormSubmitted) {
  return (
    <div className="thank-you-message">
    <div class="wrapper-1">
    <div class="wrapper-2">
      <h1 className="merci">Merci pour votre message !</h1>
      <p>Nous reviendrons vers vous dès que possible.</p><br />
      <p className="signature">Clermont toit</p>
      <button class="go-home">
      Accueil
      </button>
      </div>
   
    </div>
    </div>
  );
}

  return (
    <>
      <div className="contact-container">
        <p className="centered-text">
          Remplissez le formulaire ci-dessous
          <br />
          pour que nous ayons déjà le plaisir de vous connaître
          <br />
          et de découvrir l’ampleur de votre projet !<br />
          <br />
          Ou appelez-nous directement, si vous le souhaitez, au{"   "}
          <a href="tel:+33789433813" className="btn animated-button ms-2">
            07 89 43 38 13
          </a>
        </p>
        <h1 className="booking">Contacter Clermont Toit</h1>
        <form id="formContact" onSubmit={handleSubmit}>
          <div className="form-group-left">
            <label htmlFor="prenom">Prénom :</label>
            <input
              type="text"
              id="prenom"
              name="prenom"
              value={prenom}
              placeholder="Votre Prénom"
              required
              autoComplete="given-name"
              onChange={(e) => setPrenom(e.target.value)}
            />
          </div>
          <div className="form-group-right">
            <label htmlFor="nom">Nom :</label>
            <input
              type="text"
              id="nom"
              name="nom"
              value={nom}
              placeholder="Votre Nom"
              required
              autoComplete="family-name"
              onChange={(e) => setNom(e.target.value)}
            />
          </div>
          <div className="form-group-left">
            <label htmlFor="email">Email :</label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              placeholder="Votre E-Mail"
              required
              autoComplete="email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="form-group-right">
            <label htmlFor="telephone">Numéro de téléphone :</label>
            <input
              type="tel"
              id="telephone"
              name="telephone"
              value={telephone}
              placeholder="Votre Téléphone"
              required
              onChange={(e) => setTelephone(e.target.value)}
            />
          </div>
          <div className="form-group-full">
            <label htmlFor="message">Message :</label>
            <textarea
              id="message"
              name="message"
              value={message}
              placeholder="Votre Message"
              rows="5"
              required
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
          </div>
          {/* Acceptation des conditions */}
          <label className="request">
            <input type="checkbox" name="acceptConditions" required />
            J'ai lu et accepte la{" "}
            <Link to="/Policy" title="politique de confidentialité">
              politique de confidentialité
            </Link>{" "}
            du site. J'accepte que ces informations soient utilisées dans le
            cadre de ma demande.
          </label>
          {/* Note sur les champs obligatoires */}
          <small id="requiredFields" className="form-info">
            * Champs obligatoires
          </small>
          <input type="hidden" name="formContactSubmit" value="1" />
          <div className="form-group">
            <button type="submit" className="btn-contact">
              Envoyer
            </button>
          </div>
        </form>
      </div>
      <div className="final">
        <strong>
          Travaillons ensemble
          <br />
          à faire de vos idées, <br />
          une réalité !
        </strong>
      </div>
    </>
  );
}

export default FormulaireComponent;
