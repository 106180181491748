import React from "react";
import "./A-Propos.css";

const APropos = () => {
  return (
    <section className="apropos">
      <p className="text-center mb-4 titre-apropos">
        Nous souhaitons répondre à vos besoins et{" "}
        <strong>vous présenter l’équipe de Clermont Toit !</strong>
      </p>

      <h2 className="h2 mb-3">
        L’équipe de Clermont Toit vous accueille à Clermont-Ferrand
      </h2>

      <h3 className="h3 mb-3">Qui sommes-nous ?</h3>

      <p className="mb-4">
        Nous sommes{" "}
        <strong>
          une équipe de trois professionnels créatifs, motivés et engagés
        </strong>
        . C’est <strong>ensemble</strong> que nous{" "}
        <strong>collaborerons</strong> pour votre
        <strong> toiture</strong>, afin de vous proposer des
        <strong> résultats harmonieux</strong>. Au fil du temps, et à travers
        notre amitié, nous avons fondé une{" "}
        <strong>véritable méthode de travail</strong>, pour collaborer
        efficacement à l’élaboration de tout projet de rénovation. Avec{" "}
        <strong>le résultat de plus de 20 ans d’expérience combinée</strong>,
        sur divers chantiers en tant que salariés depuis nos parcours de
        formation respectifs, nous vous proposons, aujourd’hui, une
        <strong> expertise</strong> à la hauteur de
        <strong> vos attentes</strong> et de vos{" "}
        <strong>questionnements</strong>.
      </p>
    </section>
  );
};

export default APropos;
