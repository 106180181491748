import React from "react";
import "./carousel3D.css";

function Carousel3DImages() {
  return (
    <div className="container my-5"> {/* Ajout d'un conteneur Bootstrap avec des marges */}
      <div className="box d-flex justify-content-center"> {/* Flexbox pour le centrage */}
        {Array.from({ length: 11 }).map((_, index) => (
          <span key={index} style={{ "--i": index + 1 }}>
            <img
              src={`https://www.clermont-toit.fr/Assets/Images/Zinguerie-toiture${index}.webp`}
              alt={`Zinguerie toiture ${index}`}
              className="img-fluid" 
            />
          </span>
        ))}
      </div>
    </div>
  );
}

export default Carousel3DImages;
