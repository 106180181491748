import React from "react";
import { Link } from "react-router-dom";
import "./footer.css";

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <div className="container-footer">
          <div className="row">
            <div className="logo-footer">
              <img
                src="https://www.clermont-toit.fr/Assets/Images/Logo-Footer-Clermont-Toit.png"
                className="logo-footer"
                alt="Logo Clermont Toit"
              />
            </div>
            <div className="infos">
              <div className="infos-siege">
                <strong>Siège social</strong>
                <p>12, rue Newton - 63100 Clermont-Ferrand</p>
                <div className="footer-contact">
                  <div className="phone">
                    <i className="fas fa-phone phone"></i>
                    <a
                      className="nav-link phone"
                      href="tel:+33789433813"
                      title="Contacter Clermont Toit"
                    >
                      &nbsp;&nbsp;&nbsp;07 89 43 38 13
                    </a>
                  </div>
                  <div className="mail">
                    <i className="fas fa-envelope phone"></i>
                    <a href="mailto:contact@clermont-toit.fr" className="phone">
                      &nbsp;&nbsp;&nbsp;contact@clermont-toit.fr
                    </a>
                  </div>
                </div>
              </div>
              <div className="footer-schedules">
                <strong className="schedules">Horaires d'ouverture</strong>
                <p className="days">Du lundi au vendredi : 7h30 / 18h00</p>
              </div>
            </div>
          </div>
        </div>
        <div className="mentions">
          <div className="container-mentions">
            <Link to="/MentionsLegales" title="Mentions légales">
              Mentions légales
            </Link>
            &nbsp;&amp;&nbsp;
            <Link to="/CGV" title="CGIV">
              CGIV
            </Link>
          </div>
        </div>
        <div className="retz">
          <div className="container-retz">
            <Link
              to="https://retz-o-lution-informatique.fr/"
              title="propulse par Retz-o-lution-informatique"
            >
              Propulsé par : Retz-o-lution-informatique
            </Link>
          </div>
        </div>
      </footer>
      {/* Le formulaire et les scripts externes doivent être gérés différemment en React. Voir les notes ci-dessous. */}
    </>
  );
};

export default Footer;
