import React, { useState, useEffect } from "react";
// Importez GSAP
import { gsap } from "gsap";
import "./customNavbar.css";
import { Navbar, Nav, Container } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

function CustomNavbar({ loggedIn, firstname, lastname }) {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    // Animation GSAP pour le logo
    gsap.fromTo(
      ".logo",
      { scale: 0.5, opacity: 0 },
      { scale: 1, opacity: 1, duration: 3, ease: "power2.out" }
    );
  }, []); // Le tableau de dépendances vide signifie que cet effet s'exécute une fois au montage

  const closeMenu = () => {
    setExpanded(false);
  };

  return (
    <Navbar expanded={expanded} bg="light" expand="lg" fixed="top">
      <Container>
        <Navbar.Brand href="/">
          <img
            className="logo"
            src="https://www.clermont-toit.fr/Assets/Images/Logo-Footer-Clermont-Toit.png"
            alt="Clermont-toit logo"
          />
        </Navbar.Brand>
        
        <div className="cta">
          <a href="tel:+33764630607" className="btn-cta animated-button ms-2">
            07 89 43 38 13
          </a>
        </div>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={() => setExpanded(expanded ? false : "expanded")}
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <LinkContainer to="/">
              <Nav.Link onClick={closeMenu}>Accueil</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/Realisations">
              <Nav.Link onClick={closeMenu}>Nos Réalisations</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/APropos">
              <Nav.Link onClick={closeMenu}>A Propos</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/Contact">
              <Nav.Link onClick={closeMenu}>Contact</Nav.Link>
            </LinkContainer>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default CustomNavbar;
