import React from "react";
import "./plan.css";

function Plan() {
  return (
    <div>
      <h1 className="adress">Venez nous rencontrer !</h1>
      <iframe
        title="Localisation de l'entrepôt Clermont-Toit sur Google Maps"
        src="https://maps.google.fr/maps?width=600&amp;height=450&amp;hl=en&amp;q=12%20rue%20Newton%20Clermont-Ferrand+(Clermont%20Toit)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
        width="600"
        height="450"
        style={{ border: 0 }}
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
      <div className="separateur"></div>
    </div>
  );
}

export default Plan;
