import React from "react";
import "./contact.css";
import Plan from "../../Components/Plan/plan.jsx";
import Formulaire from "../../Components/Formulaire/formulaire.jsx";


const Contact = () => {
  return (
    <div>
      <Plan />
      <Formulaire />
    </div>
  );
};

export default Contact;
