import React, { useState } from "react";
import Photos from "./galeriePhotos";
import "./galerie.css";

const Galerie = () => {
  const [items, setItems] = useState(Photos);
  const [active, setActive] = useState("Tout");
  const [expandedCardId, setExpandedCardId] = useState(null);

  const handleCardClick = (id) => {
    if (expandedCardId === id) {
      setExpandedCardId(null); // Réduit la carte si elle est déjà agrandie
    } else {
      setExpandedCardId(id); // Agrandit la carte sélectionnée
    }
  };

  const filterItem = (categItem) => {
    const updateItems =
      categItem === "Tout"
        ? Photos
        : Photos.filter((curElem) => curElem.category === categItem);

    setItems(updateItems);
    setActive(categItem);
  };

  return (
    <>
      {expandedCardId && (
        <div
          className="expanded-card-background"
          onClick={() => setExpandedCardId(null)}
        ></div>
      )}

      <nav className="navbar navbar-light bg-light shadow-sm">
        <span className="navbar-brand mb-0 m-auto h1 text-center">
          Nos réalisations
        </span>
      </nav>
      <br />
      <div className="container">
        <ul className="nav nav-pills justify-content-center">
          <li className="nav-item">
            <a
              className={
                active === "Couverture" ? "nav-link active" : "nav-link"
              }
              href="#!"
              onClick={() => filterItem("Couverture")}
            >
              Couverture
            </a>
          </li>
          <li className="nav-item">
            <a
              className={
                active === "Zinguerie" ? "nav-link active" : "nav-link"
              }
              href="#!"
              onClick={() => filterItem("Zinguerie")}
            >
              Zinguerie
            </a>
          </li>
          <li className="nav-item">
            <a
              className={active === "Velux" ? "nav-link active" : "nav-link"}
              href="#!"
              onClick={() => filterItem("Velux")}
            >
              Pose de Velux
            </a>
          </li>
          <li className="nav-item">
            <a
              className={active === "Tout" ? "nav-link active" : "nav-link"}
              href="#!"
              onClick={() => filterItem("Tout")}
            >
              Vue d'ensemble
            </a>
          </li>
        </ul>
      </div>

      <div className="container-fluid mt-4">
        <div
          className="row"
          style={{ justifyContent: items.length <= 3 ? "center" : "" }}
        >
          {items.map((elem) => {
            const { id, name, image, title, description } = elem;
            const isExpanded = expandedCardId === id;

            return (
              <div
                className={`col-lg-3 col-md-4 col-sm-6 ${
                  isExpanded ? "expanded" : ""
                }`}
                key={id}
                onClick={() => handleCardClick(id)}
              >
                <div className="card mb-3" style={{ cursor: "pointer" }}>
                  <img className="card-img-top" src={image} alt={name} />
                  <div className="card-body">
                    <h5 className="card-title">{title}</h5>
                    <p className="card-text">
                      {isExpanded
                        ? description
                        : `${description.substring(0, 100)}...`}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Galerie;
